import { ChainId, subgraphUrls } from '@/config/networks'
import { PortfolioQuery, getBuiltGraphSDK } from './.graphclient'

export const getBuiltGraphSdkFor = (chainId: ChainId) => {
  const subgraphUrl = subgraphUrls[chainId]?.url;
  if (!subgraphUrl) {
    throw new Error(`Subgraph URL not found for chainId: ${chainId}`)
  }

  return getBuiltGraphSDK({
    url: subgraphUrl
  })
}

export const calculateTotalAmountDistributed = (
  pool?: PortfolioQuery['pools'][number]
) => {
  const currentTimestamp = Math.round(Date.now() / 1000)

  if (!pool || BigInt(pool.totalUnits) === 0n) {
    return {
      memberCurrentTotalAmountDistributed: 0n,
      poolCurrentTotalAmountDistributed: 0n
    }
  }

  const member = pool.poolMembers[0]

  if (!member) {
    return {
      memberCurrentTotalAmountDistributed: 0n,
      poolCurrentTotalAmountDistributed: 0n
    }
  }

  const poolCurrentTotalAmountDistributedDelta =
    (BigInt(currentTimestamp) - BigInt(pool.updatedAtTimestamp)) *
    BigInt(pool.flowRate)

  const poolCurrentTotalAmountDistributed =
    BigInt(pool.totalAmountDistributedUntilUpdatedAt) +
    poolCurrentTotalAmountDistributedDelta

  const memberCurrentTotalAmountDistributedDelta =
    ((poolCurrentTotalAmountDistributed -
      BigInt(member.poolTotalAmountDistributedUntilUpdatedAt)) *
      BigInt(member.units)) /
    BigInt(pool.totalUnits)

  const memberCurrentTotalAmountDistributed =
    BigInt(member.totalAmountReceivedUntilUpdatedAt) +
    memberCurrentTotalAmountDistributedDelta

  return {
    memberCurrentTotalAmountDistributed,
    poolCurrentTotalAmountDistributed
  }
}
